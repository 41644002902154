import { useEffect, useState } from 'react';
import { Table, Button, Row, Col, Input, Tooltip, Alert } from 'antd';
import { CensusDto, NoteDto, EnumSelectListDto, FacilityTypes, AwvEligibilityStatus, RequiredVisitResultDto, VisitTypes } from '@medone/medonehp-api-client';
import moment from 'moment';
import { uniq } from 'lodash';

import { togglePatientDrawer } from '../slice.patients';
import { selectNote } from '../slice.notes';
import { formatDateTime, isMobileView, getEnumFilters, isInRole } from '../../../../../shared/common/helpers';
import { useAppDispatch, useAppSelector } from '../../../../../shared/hooks';
import { sortWithNullsAtBottom } from '../../../../../shared/common/helpers/table';
import { selectRequiredVisitTypes } from '../../../../../shared/common/data/slice';
import { censusBadgeSelectors, visitDueSelectors } from '../slice';
import { Role, RolesAdminOrAnyProvider, SpecialtyRole } from '../../../../../shared/common/auth/RoleAuth';
import { selectAuth } from '../../../../../shared/common/auth/slice';
import { AuthState } from '../../../../../shared/common/auth/models';

import PatientTableMobile from '../components-mobile/patient-table';
import ActionButtons from './action-buttons';
import ProviderTypeBadge from './badges/provider-type';
import VisitDueBadge from './badges/visit-due';
import LastVisitedBadge from './badges/last-visited';
import CensusBadges from './badges';
import ScheduledBadge from './badges/scheduled';
import VisitTypeBadge from './badges/visit-type';
import EditableRoomNumber from './room-number';
import Skilled from './skilled';
import { FilterValue, SorterResult, SortOrder } from 'antd/lib/table/interface';
import { getStoredTableState, LocalStorageComponentKeys, LocalStoragePageKeys, storeTableState } from '../../../../../shared/common/helpers/page-state-helpers';
//import NextVisitBadges from './badges/next-visit-badges';

const { Column } = Table;

type Props = {
    census: CensusDto[];
};

const PatientTable = ({ census }: Props) => {
    const dispatch = useAppDispatch();
    const auth = useAppSelector<AuthState>(selectAuth);
    const latestNote = useAppSelector<NoteDto>(selectNote);
    const requiredVisitTypes = useAppSelector<EnumSelectListDto[]>(selectRequiredVisitTypes);
    const badges = useAppSelector((state) => censusBadgeSelectors.selectAll(state.census.badges));
    const visitDues = useAppSelector((state) => visitDueSelectors.selectAll(state.census.visitDues));
    const [dataSource, setDataSource] = useState<CensusDto[]>(census);
    const [searchLength, setSearchLength] = useState<number>(0);
    const [searchTemp, setSearchTemp] = useState<string>();
    const [sorterInfo, setSorter] = useState<SorterResult<any>>();
    const [filterInfo, setFilters] = useState<Record<string, FilterValue | null>>();

    const showRequiredVisitColumns = isInRole(auth.permissions, [
        Role.POST_ACUTE_ADMIN,
        Role.PHYSICIAN,
        Role.ADVANCED_PRACTICE_PRACTITIONER,
        Role.CLINICAL_COORDINATOR,
        SpecialtyRole.Primary,
    ]);

    useEffect(() => {
        let patientIntakeId: string | number = localStorage.getItem('patientIntakeId');
        if (patientIntakeId) {
            patientIntakeId = parseInt(patientIntakeId);

            const item = census.find((x) => x.patientIntakeId === patientIntakeId);
            if (item != null) {
                dispatch(togglePatientDrawer(item));
            }

            localStorage.removeItem('patientIntakeId');
        }

        setDataSource(census);

        if (searchTemp) {
            processSearch(null, searchTemp);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, census]);

    useEffect(() => {
        const tableState = getStoredTableState([LocalStoragePageKeys.census, LocalStorageComponentKeys.patientTable]);

        if (!tableState) {
            return;
        }

        setFilters(tableState.filters);
        setSorter(tableState.sorter);
    }, []);

    // const renderVisitBadges = (censusDto: CensusDto) => {
    //     // PLAN: In the future we may add this to replace the regulatory visit columns on the right side of the table
    //     return (
    //         <>
    //             <Row gutter={0}>
    //                 <Col span={24}>
    //                     <NextVisitBadges censusDto={censusDto} />
    //                 </Col>
    //             </Row>
    //         </>
    //     );
    // };

    const renderPatientName = (item: CensusDto) => {
        const isDischarged = item.dischargeDate != null;
        const showAsDischarged = isDischarged && !item.canReturnFromED;

        return (
            <>
                <Row gutter={0}>
                    <Col span={24}>
                        <Button type="link" onClick={() => dispatch(togglePatientDrawer(item))} className={showAsDischarged && 'patient-discharged'}>
                            <Tooltip title={isDischarged && `Discharged on ${formatDateTime(item.dischargeDate)}`}>{item.name}</Tooltip>
                        </Button>
                    </Col>
                </Row>

                <CensusBadges patientIntakeId={item.patientIntakeId} patientTypeIds={item.patientTypeIds} canReturnFromED={item.canReturnFromED} />
            </>
        );
    };

    const renderAWV = (item: CensusDto) => {
        const fontClass = item.awvEligibilityStatus === AwvEligibilityStatus.EligibleWithRecentQueue ? 'text-green' : '';

        return item.awvEligibilityStatus !== AwvEligibilityStatus.NotEligible && <span className={fontClass}>AWV</span>;
    };

    // Allows more granular control over search parameters.
    // For instance, allows formatting and searching of moment objects
    const processResults = (o: CensusDto, k: string, searchValue: string) => {
        if (moment.isMoment(o[k])) {
            return o[k].format('L').toLowerCase().includes(searchValue.toLowerCase());
        } else {
            return String(o[k]).toLowerCase().includes(searchValue.toLowerCase());
        }
    };

    const processSearch = (e, valueOverride: string = null) => {
        const searchValue = valueOverride ?? e.target.value;

        // If the new search term is shorter than what it was before, the user is deleting letters.
        // We need to reset the collection being searched in this case to allow for finding different
        // results from the full set.
        if (searchValue.length < searchLength) {
            setDataSource(census);
        }

        setSearchLength(searchValue.length);

        if (!valueOverride) {
            setSearchTemp(searchValue);
        }

        if (searchValue.length >= 3) {
            const filteredData = dataSource.filter((o) => Object.keys(o).some((k) => processResults(o, k, searchValue)));

            setDataSource(filteredData);
        } else if (searchValue.length === 0) {
            setDataSource(census);
        }
    };

    const renderSearch = () => {
        return (
            <Row gutter={24}>
                <Col span={isMobileView() ? 22 : 12}>
                    <Input className="census-search" placeholder="Search by name or room number" onChange={(e) => processSearch(e)} />
                </Col>
            </Row>
        );
    };

    const getProviderTypes = (): number[] => {
        return uniq(visitDues?.filter((x) => x?.providerVisitType).map((x) => x?.providerVisitType)) ?? [];
    };

    const getVisitTypeOrder = (visitDue: RequiredVisitResultDto) => {
        if (!visitDue) {
            return null;
        }

        // Current order: [H&P] [H&P/Annual H&P] [Annual H&P] [Follow Up/Annual H&P] [Follow Up]
        switch (visitDue.visitType) {
            case VisitTypes.HistoryAndPhysical:
                if (visitDue.isAnnualHpDue) {
                    return 15;
                } else {
                    return 10;
                }
            case VisitTypes.FollowUp:
                if (visitDue.isAnnualHpDue) {
                    return 30;
                } else {
                    return 40;
                }
            default:
                if (visitDue.isAnnualHpDue) {
                    return 20;
                } else {
                    // Must return null so that they sort to the bottom regardless of direction
                    return null;
                }
        }
    };

    // Because visit type does not include Annual H&P, we need custom logic to sort it.
    const sortVisitType = (a: CensusDto, b: CensusDto, direction: SortOrder) => {
        let itemA = visitDues.find((x) => x.patientIntakeId === a.patientIntakeId);
        let itemB = visitDues.find((x) => x.patientIntakeId === b.patientIntakeId);

        return sortWithNullsAtBottom({ ...itemA, visitTypeOrder: getVisitTypeOrder(itemA) }, { ...itemB, visitTypeOrder: getVisitTypeOrder(itemB) }, direction, 'visitTypeOrder');
    };

    const renderCensus = () => {
        if (isMobileView()) {
            return <PatientTableMobile census={dataSource} />;
        }

        return (
            <Table
                size="large"
                rowKey={(x) => `${x.patientIntakeId ?? 0}-${x.name}`}
                pagination={false}
                dataSource={dataSource}
                expandable={{
                    indentSize: 0,
                    rowExpandable: () => isInRole(auth?.permissions, RolesAdminOrAnyProvider),
                    expandedRowRender: (censusData) => <ActionButtons census={censusData} />,
                }}
                className="census-table"
                onChange={(pagination, filters, sorter, { currentDataSource }) => {
                    storeTableState([LocalStoragePageKeys.census, LocalStorageComponentKeys.patientTable], { pagination, filters, sorter });

                    setFilters(filters);
                    setSorter(sorter as any);
                }}
            >
                <Column<CensusDto>
                    title=""
                    key="facilityType"
                    width={75}
                    filters={[
                        { text: 'Skilled', value: FacilityTypes.SkilledNursingFacility },
                        { text: 'Not Skilled', value: 0 },
                    ]}
                    onFilter={(value, record) =>
                        value === FacilityTypes.SkilledNursingFacility ? record.facilityType === value : record.facilityType > FacilityTypes.SkilledNursingFacility
                    }
                    sorter={(a, b, direction) => sortWithNullsAtBottom(a, b, direction, 'facilityType')}
                    render={(item) => <Skilled census={item} />}
                    filteredValue={filterInfo?.facilityType || null}
                    sortOrder={(sorterInfo?.columnKey === 'facilityType' && sorterInfo.order) || null}
                />

                <Column<CensusDto>
                    width={120}
                    key="roomNumber"
                    title="Room #"
                    className="text-nowrap"
                    sorter={(a, b, direction) => sortWithNullsAtBottom(a, b, direction, 'roomNumber')}
                    render={(data: CensusDto) => <EditableRoomNumber census={data} />}
                    sortOrder={(sorterInfo?.columnKey === 'roomNumber' && sorterInfo.order) || null}
                />

                <Column<CensusDto>
                    title="Scheduled"
                    key="quickNoteScheduled"
                    width={200}
                    className="text-nowrap"
                    filters={[
                        { text: 'Scheduled', value: true },
                        { text: 'Not Scheduled', value: false },
                    ]}
                    onFilter={(value, record) => {
                        const item = badges.find((x) => x.patientIntakeId === record.patientIntakeId);

                        return value ? item?.scheduledUser != null : item?.scheduledUser == null;
                    }}
                    sorter={(a, b, direction) => {
                        const itemA = badges.find((x) => x.patientIntakeId === a.patientIntakeId);
                        const itemB = badges.find((x) => x.patientIntakeId === b.patientIntakeId);

                        return sortWithNullsAtBottom(itemA, itemB, direction, 'scheduledUser');
                    }}
                    render={(data: CensusDto) => <ScheduledBadge patientIntakeId={data.patientIntakeId} />}
                    filteredValue={filterInfo?.quickNoteScheduled || null}
                    sortOrder={(sorterInfo?.columnKey === 'quickNoteScheduled' && sorterInfo.order) || null}
                />

                {/* <Column<CensusDto>
                    title="Next Visit"
                    key="name"
                    width={350}
                    sorter={(a, b) => nextVisitSorter(a, b)}
                    defaultSortOrder="ascend"
                    sortOrder="ascend"
                    render={(item) => renderVisitBadges(item)}
                    className="visit-badges"
                /> */}

                <Column<CensusDto>
                    title="Patient Name"
                    key="name"
                    width={350}
                    sorter={(a, b, direction) => sortWithNullsAtBottom(a, b, direction, 'name')}
                    render={(item) => renderPatientName(item)}
                    className="name-with-badges"
                    sortOrder={(sorterInfo?.columnKey === 'name' && sorterInfo.order) || null}
                />

                {showRequiredVisitColumns && (
                    <>
                        <Column<CensusDto>
                            title="AWV"
                            key="awvEligibilityStatus"
                            width={120}
                            sorter={(a, b, direction) => sortWithNullsAtBottom(a, b, direction, 'awvEligibilityStatus')}
                            render={(item) => renderAWV(item)}
                            sortOrder={(sorterInfo?.columnKey === 'awvEligibilityStatus' && sorterInfo.order) || null}
                        />

                        <Column<CensusDto>
                            title="Visit Due"
                            key="overDueInDays"
                            sorter={(a, b, direction) => {
                                const itemA = visitDues.find((x) => x.patientIntakeId === a.patientIntakeId);
                                const itemB = visitDues.find((x) => x.patientIntakeId === b.patientIntakeId);

                                return sortWithNullsAtBottom(itemA, itemB, direction, 'overDueInDays');
                            }}
                            render={(data: CensusDto) => (
                                <VisitDueBadge admissionDate={data.admissionDate} admittedToId={data.admittedToId} patientIntakeId={data.patientIntakeId} />
                            )}
                            sortOrder={(sorterInfo?.columnKey === 'overDueInDays' && sorterInfo.order) || null}
                        />

                        <Column<CensusDto>
                            title="Visit Type(s)"
                            key="visitType"
                            sorter={sortVisitType}
                            render={(data: CensusDto) => <VisitTypeBadge patientIntakeId={data.patientIntakeId} />}
                            sortOrder={(sorterInfo?.columnKey === 'visitType' && sorterInfo.order) || null}
                        />

                        <Column<CensusDto>
                            title="Provider Type"
                            key="providerVisitType"
                            filters={getEnumFilters(requiredVisitTypes).filter((x) => getProviderTypes().includes(x.value))}
                            onFilter={(value, record) => {
                                const item = visitDues.find((x) => x.patientIntakeId === record.patientIntakeId);

                                return item?.providerVisitType === value;
                            }}
                            width={150}
                            sorter={(a, b, direction) => {
                                const itemA = visitDues.find((x) => x.patientIntakeId === a.patientIntakeId);
                                const itemB = visitDues.find((x) => x.patientIntakeId === b.patientIntakeId);

                                return sortWithNullsAtBottom(itemA, itemB, direction, 'providerVisitType');
                            }}
                            render={(data: CensusDto) => <ProviderTypeBadge patientIntakeId={data.patientIntakeId} />}
                            filteredValue={filterInfo?.providerVisitType || null}
                            sortOrder={(sorterInfo?.columnKey === 'providerVisitType' && sorterInfo.order) || null}
                        />
                    </>
                )}

                <Column<CensusDto>
                    title="Last Visited"
                    key="last-visited"
                    width={150}
                    sorter={(a, b, direction) => {
                        const itemA = badges.find((x) => x.patientIntakeId === a.patientIntakeId);
                        const itemB = badges.find((x) => x.patientIntakeId === b.patientIntakeId);

                        return sortWithNullsAtBottom(itemA, itemB, direction, 'latestSignedNoteServiceDate');
                    }}
                    render={(data: CensusDto) => <LastVisitedBadge latestNote={latestNote} patientIntakeId={data.patientIntakeId} />}
                    sortOrder={(sorterInfo?.columnKey === 'last-visited' && sorterInfo.order) || null}
                />
            </Table>
        );
    };

    return census.length > 0 ? (
        <>
            {renderSearch()}
            {renderCensus()}
        </>
    ) : (
        <Alert showIcon type="info" message="No patients found for this facility." />
    );
};

export default PatientTable;
