import { ApiException, Genders, ProblemDetails, ValidationProblemDetails } from '@medone/medonehp-api-client';
import { Modal } from 'antd';
import { toast, ToastContent } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { startCase } from 'lodash';
import { DuplicateMbiError } from '.';
import { formatDateTime, getEnumValue, renderPatientFullName } from './helpers';

export const toastError = (content: ToastContent) => {
    const contentStr = content?.toString();

    // These are messages that come from the generated swagger client which we don't have much control over currently
    if (contentStr && (contentStr.includes('includes is not a function') || contentStr.includes('Cannot read property') || contentStr.includes('CanceledError'))) {
        return;
    }

    toast.error(content, {
        toastId: contentStr,
    });
};

const validationModal = (content: JSX.Element) => {
    Modal.error({
        content,
        width: 600,
        okText: 'Dismiss',
        icon: null,
        className: 'error-modal',
        zIndex: 2000,
    });
};

const handleFallbackError = (error: any) => {
    if (error != null && error.result != null && Object.keys(error.result).includes('message')) {
        toastError(error.result.message);
        return;
    }

    if (error != null && Object.keys(error).includes('errorFields')) {
        const errorMessage = error.errorFields.map((field, i) => {
            let fieldName = field.name.join(' ');

            if (field.name.length > 1 && field.name[0] === 'medicalSummary') {
                // Remove Medical Summary from validation field name on the error output
                field.name.shift();

                fieldName = field.name.join(' ');
            }

            const obj = {
                name: startCase(fieldName),
                errors: field.errors.join('<br />'),
            };

            return (
                <li key={i}>
                    <strong>{obj.name}:</strong> {obj.errors}
                </li>
            );
        });

        validationModal(
            <>
                <p>
                    <strong>Some fields failed to pass validation. Please check the form.</strong>
                </p>

                <ul>{errorMessage}</ul>
            </>
        );

        return;
    }

    if (error != null) {
        toastError(error.toString());
        return;
    }

    toastError('An unknown error has occurred');
};

export const handleError = (error: any, callback?: () => void | null) => {
    const errorStr = error?.toString();

    // This error comes from cancelled requests, this is intentional so we ignore this reporting
    if (
        errorStr.includes("Cannot read properties of undefined (reading 'status')") ||
        errorStr.includes('due to a transient failure') ||
        errorStr.includes('Cannot issue SAVE TRANSACTION') ||
        errorStr.includes('AxiosError') ||
        errorStr.includes('CanceledError')
    ) {
        return;
    }

    if (errorStr.includes('Network Error')) {
        toastError(`Network Error while calling: ${error.config.url}`);
        return;
    }

    // This error comes from cancelled requests, this is intentional so we ignore this reporting
    if (errorStr.includes('Data may have been modified or deleted')) {
        toastError('Data has been modified and your local copy is out of date. Please refresh the page and try again if this error does not go away.');
    }

    if (ApiException.isApiException(error)) {
        if (error.result instanceof ValidationProblemDetails || Object.keys(error).includes('errors')) {
            const title = error.result.title;
            const detail = error.result.detail;
            const validationErrors: string[] = error.result.errors ?? [];

            validationModal(
                <>
                    <p>
                        <strong>{title}</strong>
                    </p>

                    <ul>
                        {detail && <li>{detail}</li>}

                        {Object.values(validationErrors).map((validationError) => (
                            <li key={validationError}>{validationError}</li>
                        ))}
                    </ul>
                </>
            );
        } else if (error.result instanceof ProblemDetails || Object.keys(error).includes('detail')) {
            const title = error.result.title;
            const detail = error.result.detail;

            if (!detail) {
                toastError(error.message);
            } else {
                toastError(
                    <strong>
                        <p>
                            <strong>{title}</strong>
                        </p>

                        {detail}
                    </strong>
                );
            }
        } else if (error.status === 400) {
            let validationErrors: string[] = error.response['errors'];

            // Some instances of error.response['errors'] come back as a JSON object instead of an array causing the error message to be 
            // displayed under one list-item, concatinated.  This will read that incuded array instead, so they display as multiple list-items
            if (!validationErrors.length && validationErrors['']) {
                validationErrors = validationErrors[''];
            }

            validationModal(
                <>
                    <p>
                        <strong>Some fields failed to pass validation. Please check the form.</strong>
                    </p>

                    <ul>
                        {Object.values(validationErrors).map((validationError) => (
                            <li key={validationError}>{validationError}</li>
                        ))}
                    </ul>
                </>
            );
        } else {
            if (error != null) {
                Sentry.captureException(new Error(error.toString()));
            }

            handleFallbackError(error);
        }
    } else if (error instanceof DuplicateMbiError) {
        const patient = error.patient;

        if (!patient) {
            toastError(error.message);
        } else {
            validationModal(
                <>
                    <p>
                        <h3>{error.message}</h3>
                    </p>
                    <p>
                        <strong>Patient With Matching MBI</strong>
                        <br />
                        Name: {renderPatientFullName(patient, true)}
                        <br />
                        DOB: {formatDateTime(patient.dateOfBirth)}
                        <br />
                        Gender: {getEnumValue(Genders, patient.gender)}
                        <br />
                        Patient Id: {patient.id}
                        <br />
                        MBI: {patient.mbi}
                    </p>
                </>
            );
        }
    } else {
        if (error instanceof Error) {
            Sentry.captureException(error);
        }

        handleFallbackError(error);
    }

    if (callback != null) {
        callback();
    } else {
        // Always re-throw in the event we need to catch and handle on the UI
        throw error;
    }
};
