import { Link } from 'react-router-dom';
import { Button, Tooltip, Tag, Timeline, Col, Row } from 'antd';
import { CanReturnFromEdDto, CensusBadgeRequestDto, EnumSelectListDto, FacilityQuickNotesDto, QuickNoteDto, QuickNotesDateDto } from '@medone/medonehp-api-client';
import { CheckCircleTwoTone, ClockCircleOutlined } from '@ant-design/icons';

import { fetchFacilityQuickNotes } from '../census/slice.notes';
import { fetchCensusByPatientIntakeId } from '../census/slice';
import { useAppDispatch, useAppSelector } from '../../../../shared/hooks';
import { handleError } from '../../../../shared/common/HandleErrors';
import { formatDateTimeLocal } from '../../../../shared/common/helpers';
import { CensusPaths } from '../census/index.routes';
import FaxForm from '../census/components/fax-form';
import { selectSpecialties } from '../../../../shared/common/data/slice';
import QuickNoteSignButtons from './quicknote-sign-buttons';
import { useEffect, useState } from 'react';
import { fetchQuickNotesBadgeData, quickNotesBadgeSelectors } from '../slice';
import Badges from './badges';
import { fetchCanReturnFromEDForPatientIntakeIds } from '../census/slice.patient-intakes';
import { renderQuickNoteCreatedBy } from '../../../../shared/common/helpers/renderers';

type Props = {
    facilityQuickNotes: FacilityQuickNotesDto;
    facilityId?: number;
    isMobile?: boolean;
};

const QuickNoteTimeline = ({ facilityQuickNotes, facilityId, isMobile }: Props) => {
    const dispatch = useAppDispatch();
    const allSpecialties = useAppSelector<EnumSelectListDto[]>(selectSpecialties);
    const quickNotesBadges = useAppSelector((state) => quickNotesBadgeSelectors.selectAll(state.postacute.quickNotesBadges));
    const { quickNoteDates } = facilityQuickNotes || {};
    const [canReturnFromEdLookup, setCanReturnFromEdLookup] = useState<CanReturnFromEdDto>(null);

    const refreshData = () => {
        try {
            dispatch(fetchFacilityQuickNotes(facilityQuickNotes.facilityId));
        } catch (errors) {
            handleError(errors, () => true);
        }
    };

    const noteCreatedDate = (note: QuickNoteDto) => {
        return (
            <>
                <Tag color="blue">
                    <strong>Created By:</strong> {renderQuickNoteCreatedBy(note)}
                </Tag>

                <Tag color="blue">
                    <strong>Created Date:</strong> {formatDateTimeLocal(note.createdDate, '', 'L LT')}
                </Tag>
            </>
        );
    };

    const noteAcknowledgedDate = (note: QuickNoteDto) => {
        if (!note.isNotAcknowledged && note.acknowledgements && note.acknowledgements.length > 0) {
            return (
                <>
                    {note.acknowledgements.map((v) => {
                        const specialtyName = allSpecialties.find((x) => x.id === v.specialty)?.name;

                        return (
                            <div key={`ack-${v.id}`}>
                                {`Acknowledged ${specialtyName} by ${v.acknowledgedByUserName} on ${formatDateTimeLocal(v.acknowledgedTimestamp)}`}
                                <br />
                            </div>
                        );
                    })}
                </>
            );
        }

        return null;
    };

    const renderAcknowledgeButton = (note: QuickNoteDto) => {
        if (note.isNotAcknowledged) {
            return <QuickNoteSignButtons quicknoteDto={note} facilityId={facilityQuickNotes.facilityId} patientIntakeId={note.patientIntakeId} onSignedCallback={refreshData} />;
        }

        return (
            <Tooltip placement="left" title="Acknowledged">
                <CheckCircleTwoTone style={{ fontSize: '30px' }} className="acknowledged" twoToneColor="#52c41a" />
            </Tooltip>
        );
    };

    const formatNoteForTimeline = (note: QuickNoteDto) => {
        const badges = (quickNotesBadges || []).filter((x) => x.patientIntakeId === note.patientIntakeId);
        const canReturnFromED = canReturnFromEdLookup?.lookup?.find((x) => x.key === note.patientIntakeId)?.value;

        return (
            <>
                <Row>
                    <Col span={12}>
                        <div className="d-inline-flex flex-wrap">
                            <span
                                style={{ textDecoration: 'underline', cursor: 'pointer', marginRight: '1em' }}
                                onClick={() => dispatch(fetchCensusByPatientIntakeId(note.patientIntakeId))}
                            >
                                {note.patientName}
                            </span>

                            {(badges || []).map((badge, index) => (
                                <Badges
                                    key={`${badge.patientIntakeId}-${index}`}
                                    censusBadge={badge}
                                    patientTypeIds={badge.patientTypeIds}
                                    canReturnFromED={canReturnFromED}
                                />
                            ))}
                        </div>
                    </Col>

                    <Col span={12} className="text-right pr-2">
                        <Link to={`${CensusPaths.QuickNotes.Print}/${note.id}/${facilityQuickNotes.facilityId}`} target="_blank" rel="noreferrer" className="mr-1">
                            <Button type="primary" className="print-note-btn">
                                Print
                            </Button>
                        </Link>

                        <FaxForm facilityId={facilityQuickNotes.facilityId} quickNoteId={note.id} />
                    </Col>
                </Row>

                <div className="pr-2">{note.value}</div>

                {noteCreatedDate(note)}

                <br />

                {noteAcknowledgedDate(note)}

                {note.needsFollowUp && (
                    <Tag color="blue" className="followup-tag">
                        Needs followup
                    </Tag>
                )}
            </>
        );
    };

    const renderQuickNotes = (noteDates: QuickNotesDateDto[]) => {
        if (!noteDates) {
            return null;
        }

        const notesArray = [];

        for (const noteDate of noteDates) {
            notesArray.push(
                <Timeline.Item
                    key={noteDate.date.toString()}
                    className="timeline-date"
                    dot={<ClockCircleOutlined style={{ fontSize: '20px' }} />}
                    label={noteDate.date.format('MM/DD/YYYY')}
                />
            );

            for (const note of noteDate.quickNotes) {
                notesArray.push(
                    <Timeline.Item
                        key={note.id}
                        color={`${note.isNotAcknowledged ? 'blue' : 'green'}`}
                        className="timeline-acknowledge-button"
                        label={renderAcknowledgeButton(note)}
                    >
                        {formatNoteForTimeline(note)}
                    </Timeline.Item>
                );
            }
        }

        return notesArray;
    };

    const initBadgeData = async () => {
        if (!facilityId || facilityId === 0) {
            return;
        }

        const patientIntakeIds = (quickNoteDates || [])?.flatMap((x) => x.quickNotes)?.map((x) => x.patientIntakeId);
        const dto = CensusBadgeRequestDto.fromJS({ patientIntakeIds: patientIntakeIds });

        await dispatch(fetchQuickNotesBadgeData(dto));

        const lookup = await dispatch(fetchCanReturnFromEDForPatientIntakeIds(patientIntakeIds));

        setCanReturnFromEdLookup(lookup);
    };

    useEffect(() => {
        initBadgeData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [facilityId, quickNoteDates]);

    return (
        <Timeline mode="left" className={`notes-timeline timeline mt-1${isMobile ? ' timeline-mobile' : ''}`}>
            {renderQuickNotes(facilityQuickNotes?.quickNoteDates)}
        </Timeline>
    );
};

export default QuickNoteTimeline;
