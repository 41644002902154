import React from 'react';
import { ReconciliationOutlined } from '@ant-design/icons';
import { Modal, Button, Row, Col, Popover } from 'antd';
import { CensusDto, StagedNoteDto } from '@medone/medonehp-api-client';
import StagedNotePreview from './staged-note/staged-note-preview';

type Props = {
    census: CensusDto;
    isVisible: boolean;
    currentStagedNote: StagedNoteDto;
    setVisible: (value: boolean) => void;
    handlePreppedNote: () => void;
    handleNewNote: () => void;
};

const StagedNoteModal = ({ census, currentStagedNote, isVisible, setVisible, handleNewNote, handlePreppedNote }: Props) => {
    return (
        <Modal title={`Prep Note available for ${census.name}`} open={isVisible} onCancel={() => setVisible(false)} footer={null}>
            <p>This patient has a prep note available. Would you like to use the prep note for this visit?</p>

            <p>Hover the icon below to preview the note.</p>

            <Popover overlayClassName="note-preview-popover" content={() => <StagedNotePreview note={currentStagedNote} />} placement="left" title="Note Preview">
                <Button type="default" shape="circle" icon={<ReconciliationOutlined />} />
            </Popover>

            <Row>
                <Col span={24} className="text-right">
                    <Button type="primary" onClick={handlePreppedNote}>
                        Use Prep Note
                    </Button>

                    <Button type="primary" className="ml-1" onClick={handleNewNote}>
                        Open Note
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

export default StagedNoteModal;
