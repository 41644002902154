import { NamePath } from 'antd/lib/form/interface';

export const requiredMsg = 'Is required';

const noteFieldValidation = (validateFields, field) => {
    return validateFields([field])
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());
};

export const customValidator = (ruleName: string) => {
    return ({ validateFields }) => ({
        validator(rule, value) {
            return noteFieldValidation(validateFields, ruleName);
        },
    });
};

export const HpRequiredFields = [
    ['medicalSummary', 'patientIntakeId'],
    ['medicalSummary', 'introduction'],
    ['medicalSummary', 'medicalNecessity'],
    'historyOfPresentIllness',
    'reviewOfSystems',
    ['medicalSummary', 'pastMedicalHistory'],
    'exam',
    'medicationsReviewed',
    'allergiesReviewed',
    ['medicalSummary', 'allergies'],
    ['medicalSummary', 'tobaccoUse'],
    ['medicalSummary', 'alcoholIntake'],
    ['medicalSummary', 'illicitDrugUse'],
    ['medicalSummary', 'familyHistory'],
    ['medicalSummary', 'pastSurgicalHistory'],
] as NamePath[];

export const AnnualHpRequiredFields = [
    ['medicalSummary', 'patientIntakeId'],
    ['medicalSummary', 'introduction'],
    ['medicalSummary', 'medicalNecessity'],
    'historyOfPresentIllness',
    'reviewOfSystems',
    ['medicalSummary', 'pastMedicalHistory'],
    'exam',
    'medicationsReviewed',
    'allergiesReviewed',
    ['medicalSummary', 'allergies'],
    ['medicalSummary', 'tobaccoUse'],
    ['medicalSummary', 'alcoholIntake'],
    ['medicalSummary', 'illicitDrugUse'],
    ['medicalSummary', 'familyHistory'],
    ['medicalSummary', 'pastSurgicalHistory'],
    'immunizations',
    'preventativeMeasures',
] as NamePath[];

export const PnRequiredFields = [
    ['medicalSummary', 'patientIntakeId'],
    ['medicalSummary', 'introduction'],
    'subjective',
    ['medicalSummary', 'medicalNecessity'],
    'exam',
    'medicationsReviewed',
    'allergiesReviewed',
    ['medicalSummary', 'allergies'],
] as NamePath[];

export const MsRequiredFields = [
    ['medicalSummary', 'patientIntakeId'],
    ['medicalSummary', 'introduction'],
    ['medicalSummary', 'allergies'],
    ['medicalSummary', 'pastMedicalHistory'],
    ['medicalSummary', 'pastSurgicalHistory'],
    ['medicalSummary', 'tobaccoUse'],
    ['medicalSummary', 'alcoholIntake'],
    ['medicalSummary', 'illicitDrugUse'],
    ['medicalSummary', 'familyHistory'],
] as NamePath[];

export const DsRequiredFields = [
    ['medicalSummary', 'patientIntakeId'],
    ['medicalSummary', 'introduction'],
    ['medicalSummary', 'pastMedicalHistory'],
    ['medicalSummary', 'allergies'],
    ['medicalSummary', 'medicalNecessity'],
    'subjective',
    'exam',
    'medicationsReviewed',
    'allergiesReviewed',
] as NamePath[];
