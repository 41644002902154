import { useEffect, useState } from 'react';
import { Row, Col, Radio, Card, Checkbox, Button, FormInstance, Alert, Input } from 'antd';
import { NoteDto } from '@medone/medonehp-api-client';

import { requiredMsg } from '../../../utils/notes';
import { formatDateTime, getEnumSelectListOptions } from '../../../../../../../shared/common/helpers';
import { useAppSelector } from '../../../../../../../shared/hooks';
import { selectDepressionScreeningAnswers } from '../../../../../../../shared/common/data/slice';

import { FormItemWithLock } from '../../../../../../../shared/common/components/FormItemWithLock';

type Props = {
    form: FormInstance;
    isRequired: boolean;
    isReadOnly: boolean;
    note: NoteDto;
    isLocked?: (field: string) => boolean;
    handleSaveNote: () => Promise<void>;
};

const Depression = ({ form, isRequired, isReadOnly, note, isLocked, handleSaveNote }: Props) => {
    const depressionScreeningAnswers = useAppSelector(selectDepressionScreeningAnswers);
    const [enableSurvey, setEnableSurvey] = useState<boolean>(true);
    const [enableUnableToComplete, setEnableUnableToComplete] = useState<boolean>(true);

    const getOptions = () => {
        return getEnumSelectListOptions(depressionScreeningAnswers).map((option) => (
            <Radio.Button key={option.value} value={option.value}>
                {option.label}
            </Radio.Button>
        ));
    };

    const handleClear = async () => {
        form.setFields([
            { name: 'littleInterest', value: null },
            { name: 'feelingDown', value: null },
            { name: 'unableToCompleteDueToCognitiveImpairment', value: null },
        ]);

        await handleSaveNote();

        setEnableSurvey(true);
        setEnableUnableToComplete(true);
    };

    const handleEnableQuestions = (fieldType: string, value: boolean | number) => {
        if (fieldType === 'radio') {
            if (value != null) {
                setEnableUnableToComplete(false);
            }
        } else if (fieldType === 'checkbox') {
            if (value) {
                setEnableSurvey(false);
            } else {
                setEnableSurvey(true);
            }
        }
    };

    const renderDescription = () => {
        const { lastDepressionScreeningScore, lastDepressionScreeningCognitiveImpairment } = note || {};
        if (lastDepressionScreeningScore == null && !lastDepressionScreeningCognitiveImpairment) {
            return null;
        }

        const description =
            lastDepressionScreeningScore >= 0 && lastDepressionScreeningCognitiveImpairment == null ? (
                <>
                    <strong>Score:</strong> {lastDepressionScreeningScore}
                </>
            ) : (
                <>
                    <strong>No Score:</strong> Declined due to cognitive impairment
                </>
            );

        return (
            <>
                <span>
                    <strong>Last Depression Screening:</strong> {formatDateTime(note.lastDepressionScreeningDate, '', 'L')}
                </span>

                <span className="ml-1">{description}</span>
            </>
        );
    };

    useEffect(() => {
        if (note) {
            if (note.unableToCompleteDueToCognitiveImpairment) {
                setEnableUnableToComplete(true);
                setEnableSurvey(false);

                form.setFields([
                    // To correct possible bad state in existing unsigned notes. can be removed once all existing unsigned notes are expired or completed
                    { name: 'littleInterest', value: null },
                    { name: 'feelingDown', value: null },
                ]);
            } else if (note.littleInterest || note.feelingDown) {
                setEnableSurvey(true);
                setEnableUnableToComplete(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [note]);

    // TODO: Check this workflow
    // useEffect(() => {
    //     handleSaveNote();
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [enableSurvey, enableUnableToComplete]);

    return (
        <Card
            size="small"
            title="Depression Screening"
            className="mb-2 depression-screening"
            extra={
                <Button type="primary" ghost size="small" onClick={handleClear} hidden={isReadOnly}>
                    Clear Selections
                </Button>
            }
        >
            {note?.lastDepressionScreeningDate && <Alert showIcon type="info" className="mb-1 p-1" description={renderDescription()} />}

            <FormItemWithLock name="currentDepressionScreeningId" hidden>
                <Input />
            </FormItemWithLock>

            <p className="mb-2">
                Over the <strong>last 2 weeks,</strong> how often have you been bothered by the following problems?
            </p>

            <Row gutter={24}>
                <Col span={24}>
                    <strong>1.</strong> Little interest or pleasure in doing things?
                </Col>
                <Col span={24}>
                    <FormItemWithLock name="littleInterest" rules={[{ required: isRequired, message: requiredMsg }]} className="mt-1 mb-1">
                        <Radio.Group
                            buttonStyle="solid"
                            className="radio-buttons four-col"
                            disabled={isReadOnly || isLocked('littleInterest') || !enableSurvey}
                            onChange={(e) => handleEnableQuestions(e.target.type, e.target.value)}
                        >
                            {getOptions()}
                        </Radio.Group>
                    </FormItemWithLock>
                </Col>
            </Row>

            <Row gutter={24}>
                <Col span={24}>
                    <strong>2.</strong> Feeling down, depressed or hopeless
                </Col>
                <Col span={24}>
                    <FormItemWithLock name="feelingDown" rules={[{ required: isRequired, message: requiredMsg }]} className="mt-1 mb-2">
                        <Radio.Group
                            buttonStyle="solid"
                            className="radio-buttons four-col"
                            disabled={isReadOnly || isLocked('feelingDown') || !enableSurvey}
                            onChange={(e) => handleEnableQuestions(e.target.type, e.target.value)}
                        >
                            {getOptions()}
                        </Radio.Group>
                    </FormItemWithLock>
                </Col>
            </Row>

            <Row gutter={24} align="middle">
                <Col>
                    <FormItemWithLock
                        name="unableToCompleteDueToCognitiveImpairment"
                        rules={[{ required: isRequired && enableSurvey && enableUnableToComplete, message: requiredMsg }]}
                        className="mb-1"
                        valuePropName="checked"
                    >
                        <Checkbox
                            disabled={isReadOnly || isLocked('unableToCompleteDueToCognitiveImpairment') || !enableUnableToComplete}
                            onChange={(e) => handleEnableQuestions(e.target.type, e.target.checked)}
                        >
                            Unable to obtain/complete screening due to cognitive impairment
                        </Checkbox>
                    </FormItemWithLock>
                </Col>
            </Row>

            {note?.populateDepressiveDisorder != null && <Alert showIcon type="warning" description="Major depressive disorder is likely." />}
        </Card>
    );
};

export default Depression;
