import { FormItemProps, Form } from 'antd';
import { PatientIntakeDto } from '@medone/medonehp-api-client';

import { useAppDispatch, useAppSelector } from '../../hooks';
import { isFieldLocked, isMedicalSummaryField } from '../helpers';
import { selectPatientIntake, selectPatientIntakeIsLocked, medicalSummaryFieldsLock } from '../../../post-acute/features/post-acute/census/slice.patient-intakes';
import { selectMedicalSummaryIntake, selectMedicalSummaryIntakeIsLocked } from '../../../post-acute/features/post-acute/clinical-coordinator/slice';

export function FormItemWithLock<Values = any>(props: FormItemProps<Values>): React.ReactElement {
    const dispatch = useAppDispatch();
    const currentPatientIntake = useAppSelector<PatientIntakeDto>(selectPatientIntake);
    const currentPatientIntakeIsLocked = useAppSelector<boolean>(selectPatientIntakeIsLocked);

    const nextPatientIntake = useAppSelector<PatientIntakeDto>(selectMedicalSummaryIntake);
    const nextPatientIntakeIsLocked = useAppSelector<boolean>(selectMedicalSummaryIntakeIsLocked);

    // If we're in the context of the medical summary form, return the "next" patient intake
    // otherwise assume we're on the note form
    const getActivePatientIntake = () => (currentPatientIntake?.id ? currentPatientIntake : nextPatientIntake);
    const medicalSummaryFieldsLocked = () => (currentPatientIntake?.id ? currentPatientIntakeIsLocked : nextPatientIntakeIsLocked);

    const fieldName = props.name.toString();
    const isLocked = isFieldLocked(medicalSummaryFieldsLocked(), fieldName);
    const lockedClass = isLocked ? 'is-locked' : '';
    const className = props.className ? `${props.className} ${lockedClass}` : lockedClass;

    const shouldLock = (e) => {
        // This should not run under the context of the medical summary queue form
        if (currentPatientIntake != null && isMedicalSummaryField(fieldName) && e.currentTarget !== e.target) {
            const { readOnly } = e.target;

            // Don't try to send lock request if the field is already locked
            return !readOnly;
        }

        return false;
    };

    const handleFocus = async (e) => {
        // FYI: Unlock happens via the MedicalSummaryFieldsUpdated SignalR event handler

        if (shouldLock(e)) {
            // Prevent editing until lock comes back successfull
            e.target.readOnly = true;

            const result = await dispatch(medicalSummaryFieldsLock(getActivePatientIntake().medicalSummary, true));
            if (result) {
                e.target.readOnly = false;
            }
        }
    };

    const formItemName = isMedicalSummaryField(fieldName) ? ['medicalSummary', fieldName] : fieldName;

    return (
        <div onFocus={handleFocus}>
            <Form.Item {...props} className={className} name={formItemName} extra={isLocked && <>Field Locked</>} />
        </div>
    );
}
